// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  background-color: #FCFEFF;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-left {
    width: 60%;
    height: 100vh;
    background: linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%);
    display: flex;
    align-items: center;

    @media (max-width: 1120px) {
        display: none;
    }

    .login-logo {
      position: absolute;
      top: 0;
      margin-top: 80px;
      padding: 0 116px;
    }

    .login-left-box {
      width: 100%;
      padding: 0 116px;
      text-align: left;
      color: #FFFFFF;

      .login-left-box-text {
        max-width: 75%;

        h1 {
          font-size: 40px;
          font-weight: 700;
        }

        h2 {
          font-size: 18px;
          font-weight: 500;
        }
      }

      .login-left-box-img {
        text-align: right;
        margin-top: 22px;
      }
    }
  }
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 25px;
  background-color: #007aff;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #005bb5;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB;IACE,UAAU;IACV,aAAa;IACb,6EAA6E;IAC7E,aAAa;IACb,mBAAmB;;IAEnB;QACI,aAAa;IACjB;;IAEA;MACE,kBAAkB;MAClB,MAAM;MACN,gBAAgB;MAChB,gBAAgB;IAClB;;IAEA;MACE,WAAW;MACX,gBAAgB;MAChB,gBAAgB;MAChB,cAAc;;MAEd;QACE,cAAc;;QAEd;UACE,eAAe;UACf,gBAAgB;QAClB;;QAEA;UACE,eAAe;UACf,gBAAgB;QAClB;MACF;;MAEA;QACE,iBAAiB;QACjB,gBAAgB;MAClB;IACF;EACF;AACF;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".App {\n  text-align: center;\n  background-color: #FCFEFF;\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .login-left {\n    width: 60%;\n    height: 100vh;\n    background: linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%);\n    display: flex;\n    align-items: center;\n\n    @media (max-width: 1120px) {\n        display: none;\n    }\n\n    .login-logo {\n      position: absolute;\n      top: 0;\n      margin-top: 80px;\n      padding: 0 116px;\n    }\n\n    .login-left-box {\n      width: 100%;\n      padding: 0 116px;\n      text-align: left;\n      color: #FFFFFF;\n\n      .login-left-box-text {\n        max-width: 75%;\n\n        h1 {\n          font-size: 40px;\n          font-weight: 700;\n        }\n\n        h2 {\n          font-size: 18px;\n          font-weight: 500;\n        }\n      }\n\n      .login-left-box-img {\n        text-align: right;\n        margin-top: 22px;\n      }\n    }\n  }\n}\n\nbutton {\n  padding: 10px 20px;\n  font-size: 1rem;\n  border-radius: 25px;\n  background-color: #007aff;\n  color: white;\n  border: none;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #005bb5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
