import Cookies from 'js-cookie';
import React, { useState, useEffect } from "react";
import './App.css';
import Chatbot from './components/Chatbot';
import Login from './components/Login';
import loginImage from './assets/images/login-img.png';
import logo from "./assets/images/logo-white.png";


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState("");
  const [assistant_name, setAssistantName] = useState("");
  const [available_services, setAvailableServices] = useState([]);

  // Recuperar datos de autenticación del localStorage al cargar la página
  useEffect(() => {
    let storedAvailableServices;
    let storedUser;
    let storedAssistanName;
    try {
      storedUser = localStorage.getItem("user");
      storedAssistanName = localStorage.getItem("assistant_name");
      storedAvailableServices = JSON.parse(localStorage.getItem("available_services"));
    } catch (e) {
      localStorage.clear()
      Cookies.remove('token', { path: '' }) // removed!
    }
    const token = Cookies.get("token"); // Usar Cookies.get aquí

    if (storedUser && token && storedAssistanName && storedAvailableServices) {
      setAssistantName(storedAssistanName);
      setAvailableServices(storedAvailableServices);
      setUser(storedUser);
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (user, assistant_name, availableServices) => {
    setUser(user);
    setAssistantName(assistant_name);
    setAvailableServices(availableServices);
    setIsAuthenticated(true);
    localStorage.setItem("user", user); // Guardar el usuario en el localStorage
    localStorage.setItem("assistant_name", assistant_name); // Guardar el nombre del asistente en el localStorage
    localStorage.setItem("available_services", JSON.stringify(availableServices)); // Guardar si es local o no en el localStorage
  };

  return (
      <div className="App">
        {!isAuthenticated && (
            <div className="login-left">
              <div className="login-logo">
                <img src={logo} alt="Login Illustration" className="logo" width="148"/>
              </div>
              <div className="login-left-box">
                <div className="login-left-box-text">
                  <h1>Hispavista Chatbot</h1>
                  <h2>¡Hola! Bienvenido/a al asistente de Hispavista Labs.</h2>
                   <h2> Estoy aquí para ayudarte y brindarte la información que necesites.</h2>
                    <h2>¿En qué puedo ayudarte hoy?</h2>
                </div>
                <div className="login-left-box-img">
                  <img src={loginImage} alt="Login Illustration" className="login-image"/>
                </div>
              </div>
            </div>
        )}
        {isAuthenticated ? (
            <Chatbot user={user} assistant_name={assistant_name} available_services={available_services}/>
        ) : (
            <Login onLogin={handleLogin}/>
        )}
      </div>
  );
}

export default App;
