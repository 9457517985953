import React, { useState } from "react";
import Cookies from "js-cookie";
import './Login.css';
import logoImage from "../assets/images/logo.png";
import loginImage from "../assets/images/login-img.png";


const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const api_host = process.env.REACT_APP_TRABAJOS_PYTHONAPI_HOST || "http://localhost:5000";
    try {
      const response = await fetch(api_host + '/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });
  
      if (response.ok) {
        const data = await response.json();
        Cookies.set('token', data.token, { expires: 1 });
  
        // Construir el array de objetos `available_services` a partir del JSON
        const available_services = Array.isArray(data.available_services) ? data.available_services.map(service => ({
          service: service.name,
          is_local_rag: service.is_local_rag || false,
        })) : [{
          service: data.available_services.name,
          is_local_rag: data.available_services.is_local_rag || false,
        }];
        // Llamar a la función onLogin con los datos necesarios
        onLogin(username, data.assistant_name, available_services);
      } else {
        const result = await response.json();
        setError(result.error || "Error al autenticar");
      }
    } catch (err) {
      setError("Error de red");
    }
  };
  return (
      <div className="login-container">
        <div className="login-box-img">
          <img src={loginImage} alt="Login Illustration" className="login-image" width="300" />
        </div>
        <div className="login-box">
          <h1 className="login-title">Iniciar Sesión</h1>
          {error && <p style={{color: "red"}}>{error}</p>}
          <form onSubmit={handleSubmit} className="login-form">
            <input
                type="text"
                placeholder="Usuario o email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="input-field"
            />
            <input
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="input-field"
            />
            <button type="submit" className="login-button">Login</button>
            <a href="#" className="forgot-password">¿Has olvidado tu contraseña?</a>
          </form>
        </div>
      </div>
  );
};

export default Login;
