import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeReact from 'rehype-raw'; 
import './Chatbot.css';
import logoImage from '../assets/images/logo.png';
import loginImage from "../assets/images/login-img.png";


const Chatbot = ({ user, assistant_name, available_services }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiSelection, setApiSelection] = useState(available_services[0]?.service);
  const [threadID, setThreadID] = useState("new");

  const token = Cookies.get("token");
  const api_host = process.env.REACT_APP_TRABAJOS_PYTHONAPI_HOST || "http://localhost:5000";

  useEffect(() => {
    const storedMessages = localStorage.getItem("messages");
    const storedThreadID = localStorage.getItem("threadID");
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
    if (storedThreadID) {
      setThreadID(storedThreadID);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    localStorage.setItem("threadID", threadID);
  }, [threadID]);

  const chatWindowRef = useRef(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async () => {
    setError(null);
    const newMessage = { sender: "user", text: input };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInput("");
    setLoading(true);

    try {
      const selectedService = available_services.find(service => service.service === apiSelection);
      const is_local_rag = selectedService?.is_local_rag || false;

      const apiUrl = is_local_rag ? `/assistant_rag/${apiSelection}/${threadID}` : `/assistant/${apiSelection}/${threadID}`;
      
      const url = new URL(api_host + apiUrl);

      const response = await fetch(url, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ question: input }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 401) {
          Cookies.remove("token");
          window.location.reload();
          return;
        }
        throw new Error(`Error: ${response.status} - ${errorData.error.message || response.statusText}`);
      }

      const result = await response.json();

      if (threadID === "new" && result.thread_id) {
        setThreadID(result.thread_id);
      }

      setMessages(prevMessages => [
        ...prevMessages, 
        { sender: user, text: result.response }
      ]);
    } catch (error) {
      if (error.message.includes("429")) {
        setError("Se ha superado el límite de uso de la API. Intenta más tarde o revisa tu plan.");
      } else {
        setError(error.message);
      }
      setMessages(prevMessages => [
        ...prevMessages, 
        { sender: user, text: "Error al conectar con la API." }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) sendMessage();
  };

  const clearChat = () => {
    setMessages([]);
    setError(null);
    setThreadID("new");
    localStorage.removeItem("messages");
    localStorage.removeItem("threadID");
  };

  const handleLogout = () => {
    Cookies.remove("token");
    window.location.reload();
  };

  return (
      <div className="chatbot-container">
        <div className="chatbot-header">
          <img src={logoImage} alt="Login Illustration" className="logo"/>
          <div className="chatbot-titles">
            <h1 className="title">Chat Bot {assistant_name}</h1>
            {/*<h2 className="subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>*/}
          </div>
          <div className="logout-button">
            <div onClick={handleLogout} className="logout-btn">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H9V2H2V16H9V18H2ZM13 14L11.625 12.55L14.175 10H6V8H14.175L11.625 5.45L13 4L18 9L13 14Z"
                    fill="#5F6368"/>
              </svg>
              Cerrar sesión
            </div>
          </div>
        </div>

        {available_services && available_services.length > 1 && (
            <div className="api-selector">
              <select
                  id="api-select"
                  className="api-select"
                  value={apiSelection}
                  onChange={(e) => {
                    setApiSelection(e.target.value);
                    clearChat();
                  }}
              >
                <option value="" disabled>
                  Selecciona API
                </option>

                {available_services.map(service => (
                    <option key={service.service} value={service.service}>
                      {service.service}
                    </option>
                ))}
              </select>
            </div>
        )}


        {error && <p className="error-message" style={{color: "red"}}>{error}</p>}

        <div className="chat-window" ref={chatWindowRef}>
          <div className="chat-content">
            {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender === user ? "bot-message" : "user-message"}`}>
                  {/*<strong>{message.sender}: </strong>*/}
                  {message.sender !== user ? (
                      message.text
                  ) : (
                      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeReact]}>
                        {message.text}
                      </ReactMarkdown>
                  )}
                </div>
            ))}
            {loading && <div className="loader"></div>}
          </div>
          <button type="button" className="clear-button" onClick={clearChat}>
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 15V13H10.1C11.15 13 12.0625 12.6667 12.8375 12C13.6125 11.3333 14 10.5 14 9.5C14 8.5 13.6125 7.66667 12.8375 7C12.0625 6.33333 11.15 6 10.1 6H3.8L6.4 8.6L5 10L0 5L5 0L6.4 1.4L3.8 4H10.1C11.7167 4 13.1042 4.525 14.2625 5.575C15.4208 6.625 16 7.93333 16 9.5C16 11.0667 15.4208 12.375 14.2625 13.425C13.1042 14.475 11.7167 15 10.1 15H3Z" fill="#B31414"/>
            </svg>
            Vaciar chat
          </button>
        </div>

        <form onSubmit={handleSubmit} className="chat-form">
          <div className="input-container">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5 19H6.425L16.2 9.225L14.775 7.8L5 17.575V19ZM3 21V16.75L16.2 3.575C16.4 3.39167 16.6208 3.25 16.8625 3.15C17.1042 3.05 17.3583 3 17.625 3C17.8917 3 18.15 3.05 18.4 3.15C18.65 3.25 18.8667 3.4 19.05 3.6L20.425 5C20.625 5.18333 20.7708 5.4 20.8625 5.65C20.9542 5.9 21 6.15 21 6.4C21 6.66667 20.9542 6.92083 20.8625 7.1625C20.7708 7.40417 20.625 7.625 20.425 7.825L7.25 21H3ZM15.475 8.525L14.775 7.8L16.2 9.225L15.475 8.525Z"
                  fill="white"/>
            </svg>
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Escribe tu pregunta..."
                className="chat-input"
            />
            <button className="chat-button" type="submit">
              <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 24V0L29 12L0.5 24ZM3.5 19.5L21.275 12L3.5 4.5V9.75L12.5 12L3.5 14.25V19.5Z" fill="white"/>
              </svg>
            </button>
          </div>
        </form>
      </div>
  );
};

export default Chatbot;
