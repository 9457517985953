// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilos globales para la aplicación */
body {
  font-family: "Montserrat", serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
}

button {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;EACE,gCAAgC;EAChC,SAAS;EACT,UAAU;EACV,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* Estilos globales para la aplicación */\nbody {\n  font-family: \"Montserrat\", serif;\n  margin: 0;\n  padding: 0;\n  background-color: #f4f4f9;\n  color: #333;\n}\n\nbutton {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
